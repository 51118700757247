import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { MdKeyboardArrowDown } from 'react-icons/md';
import Logo from './Logo';

const NavStyles = styled.nav`
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    margin-bottom: 2rem;
  }

  ul {
    margin: 1rem 0;
    padding: 0;
    list-style: none;
    text-align: center;

    @media (min-width: 768px) {
      width: auto;
      display: grid;
      grid-template-columns: auto 1fr 1fr 1fr 1fr;
      grid-gap: 1.25rem;
      align-items: center;
      padding: 0;
    }
  }

  li {
    padding: 1rem;

    @media (min-width: 768px) {
      padding: 0;
    }
  }

  li:not(.logo) {
    border-top: 2px solid var(--blue);

    &:last-of-type {
      border-bottom: 2px solid var(--blue);
    }

    a {
      font-size: 20px;
      text-decoration: none;
    }

    @media (min-width: 768px) {
      border-top: 0 none;
      margin-top: 1.2rem;

      &:last-of-type {
        border-bottom: 0 none;
      }

      a {
        font-size: 18px;
        text-decoration: underline;
      }
    }
  }

  a {
    color: var(--blue);
    /* &[aria-current='page'] {
      color: var(--yellow);
    } */
  }
`;

function SubNav() {
  return (
    <section className="subnav">
      <ul>
        <li>
          <Link to="/">About Us</Link>
        </li>
        <li>
          <Link to="/">Services</Link>
        </li>
        <li>
          <Link to="/">Something</Link>
        </li>
      </ul>
    </section>
  );
}

export default function Nav() {
  return (
    <>
      <NavStyles>
        <ul>
          <li className="logo">
            <Logo />
          </li>
          <li>
            <Link to="/about">About Us</Link>
            {/* <button type="button">
              <MdKeyboardArrowDown />
            </button> */}
          </li>
          <li>
            <Link to="/service/dialysis-clinic">Dialysis Clinic</Link>
            {/* <Link to="/services">Services</Link> */}
          </li>
          <li>
            <Link to="/news">Community & News</Link>
          </li>
          <li>
            <Link to="/donate">Donate</Link>
          </li>
        </ul>
      </NavStyles>
    </>
  );
}
