import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    --black: #2E2E2E;
    --blue: #2C5E9C;
    --yellow: #E3E226;
    --red: #FF4949;
    --light-orange: #E8A72D;
    --orange: #D5742D;
    --teal: #309C9A;
    --green: #117B3F;
    --white: #FFFFFF;
    --grey: #efefef;
    --violet: #3B3F98;
  }

  * {
    box-sizing: border-box;
  }
  
  html {
    font-size: 16px;
  }

  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }

  section {
    margin: 0 0 1rem;

    @media (min-width: 768px) {
      margin: 0 0 2rem;
    }
  }

  button,
  .button {
    display: block;
    background: var(--blue);
    border: 4px solid var(--blue);
    color: white;
    font-weight: 600;
    font-size: 1.25rem;
    padding: 0.6rem 1rem;
    cursor: pointer;
    --cast: 0px;
    box-shadow: var(--cast) var(--cast) 0 var(--yellow);
    transition: all 0.2s;
    margin: 1rem 0;

    &--light {
      background: var(--white);
      color: var(--blue);
    }

    @media (min-width: 450px) {
      display: inline-block;
      width: auto;
    }

    &:hover,
    &:focus {
      --cast: 4px;
    }
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  /* Scrollbar Styles */
  body::-webkit-scrollbar {
    width: 12px;
  }

  html {
    scrollbar-width: thin;
    scrollbar-color: var(--violet) var(--white);
  }

  body::-webkit-scrollbar-track {
    background: var(--white);
  }

  body::-webkit-scrollbar-thumb {
    background-color: var(--violet) ;
    border-radius: 6px;
    border: 3px solid var(--white);
  }

  img {
    max-width: 100%;
  }

  .screenreader-only {
    clip: rect(0 0 0 0); 
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap; 
    width: 1px;
  }

  .hero {
    width: 100%; 
    height: 100%;
    position: relative;

    &--top {
      margin-top: -1rem;
    }
}

`;

export default GlobalStyles;
