import React from 'react';
import styled from 'styled-components';
import 'focus-visible';
import Nav from './Nav';
import Footer from './Footer';
import 'normalize.css';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';

const ContentStyles = styled.div`
  padding: 0 1rem;
  max-width: 1200px;
  margin: 0 auto 1rem;

  &.js-focus-visible :focus:not(.focus-visible) {
    outline: none;
  }

  &.js-focus-visible .focus-visible {
    outline: 4px solid var(--violet);
    outline-offset: 4px;
  }

  @media (min-width: 768px) {
    padding: 0 1rem;
    margin: 0 auto 2rem;
  }
`;

export default function Layout({ children }) {
  return (
    <>
      <ContentStyles className="js-focus-visible focus-visible">
        <GlobalStyles />
        <Typography />
        <Nav />
        <main>{children}</main>
      </ContentStyles>
      <Footer />
    </>
  );
}
