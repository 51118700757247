import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import logo from '../assets/images/logo.svg';
import {ImFacebook2} from 'react-icons/im';

const FooterStyles = styled.footer`
  background: var(--light-orange);
  color: var(--black);
  padding: 3rem 2rem;

  .content {
    max-width: 1200px;
    margin: 0 auto;

    @media (min-width: 768px) {
      padding: 0 1rem;
      margin: 0 auto;
      display: flex;
      flex-flow: row;
      justify-content: space-between;
    }
  }

  h2 {
    font-size: 1.25rem;
    color: black;
    margin-bottom: 0.5rem;
  }

  ul {
    padding: 0;
    margin-top: 0;
    list-style: none;
  }

  a {
    color: var(--black);
  }
`;

export default function Footer() {
  return (
    <FooterStyles>
      <div className="content">
        <div>
          <ul>
            <li>&copy; VIHCF {new Date().getFullYear()}</li>
            <li><Link to="/credits">Credits</Link></li>
          </ul>
        </div>
        <div>
          <h2>Follow us:</h2>
          <ul>
            <li><Link target="_blank" to="https://www.facebook.com/VIHealthcareFoundation"><ImFacebook2 role="img" aria-label="Virgin Islands Healthcare Foundation Facebook (opens in new tab)"/></Link></li>
          </ul>
        </div>
      </div>
    </FooterStyles>
  );
}
