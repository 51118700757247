import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import horizontalLogo from '../assets/images/horizontal-logo.svg';
import verticalLogo from '../assets/images/vertical-logo.svg';

const LogoStyles = styled.div`
  a {
    display: block;
    height: 35px;
    width: 274px;
    display: block;
    position: relative;
    margin: 0 auto;

    &::after {
      content: '';
      bottom: 0;
      left: 0;
      height: 35px;
      width: 274px;
      position: absolute;
      background: url(${verticalLogo}) no-repeat transparent;
    }

    @media (min-width: 768px) {
      &::after {
        background: url(${horizontalLogo}) no-repeat transparent;
      }
    }
  }
`;

export default function Logo() {
  return (
    <LogoStyles className="logo">
      <Link to="/">
        <span className="screenreader-only">
          Virgin Islands Healthcare Foundation Homepage
        </span>
      </Link>
    </LogoStyles>
  );
}
