import { createGlobalStyle } from 'styled-components';
import '@fontsource/open-sans';
import '@fontsource/open-sans/400-italic.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/eb-garamond';
import '@fontsource/eb-garamond/400-italic.css';
import '@fontsource/eb-garamond/500.css';
import '@fontsource/eb-garamond/600.css';

const Typography = createGlobalStyle`
  html {
    font-family: 'EB Garamond', serif;
    color: var(--black);
  }

  h1,h2,h3,h4,h5,h6 {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    color: var(--blue);
  }

  h1, h2, h3 {
    font-weight: 400;
  }

  h4, h5, h6 {
    font-weight: 500;
  }

  h1 {
    font-size: 3.25rem;
  }

  h2 {
    font-size: 2.55rem;
  }

  h3 {
    font-size: 2.25rem;
  }

  h4 {
    font-size: 1.75rem;
  }

  h5 {
    font-size: 1.375rem;
  }

  h6 {
    font-size: 1.25rem;
    color: var(--green);
  }

  a {
    color: var(--blue);
    font-weight: 600;
  }

  blockquote {
    font-size: 1.375rem;
    line-height: 1.4;
  }
  
  mark, .mark {
    background: var(--yellow);
    padding: 0 2px 2px 2px;
    margin: 0;
    display: inline;
    line-height: 1;
  }

  .center {
    text-align: center;
  }

  .tilt {
    transform: rotate(-2deg);
  }

  p, li {
    line-height: 1.4;
    font-size: 1.125rem;
  }
`;

export default Typography;
